import { useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import {Sky} from "@react-three/drei"

function RotatingIcosahedron() {
  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef();

  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame(
    (state, delta) => (
      (ref.current.rotation.x += (delta*1.5)), (ref.current.rotation.y += delta)
    )
  );

  // Return the view, these are regular Threejs elements expressed in JSX
  return (
    <mesh ref={ref} scale={2}>
      <icosahedronGeometry />
      <meshStandardMaterial color={"orange"} />
    </mesh>
  );
}

export default function App() {
  return (
    <Canvas>
      <Sky/>
      <ambientLight intensity={0.5} />
      <spotLight
        position={[0, 10, 10]}
        penumbra={1}
        decay={0}
        intensity={3.14}
      />
      <RotatingIcosahedron />
    </Canvas>
  );
}
